<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div style="margin-left:50px; margin-top: 20px;">
      <h3>{{profil.daerah}}</h3>
      </div>
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title"><span>1.</span>Kinerja Pelayanan Perizinan Berusaha</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>2.</span>Kinerja PPB</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-10">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
            
              <!--end: Wizard Step 1-->
              <!--begin: Wizard Step 2-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current" style="margin-top:-40px;">
                                <h4 class="mb-10 font-weight-bold text-dark">1.	Kinerja Pelayanan Perizinan Berusaha</h4>
                                <div class="my-5">
                                    <div class="card card-custom">
                                        <div class="card-body">
                                            <!--begin: Datatable-->
                                            <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="display:none;">No</th>
                                                        <th style="display:none;">Indikator Penilaian</th>
                                                        <th style="display:none;">Jawaban</th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Kelembagaan</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai kesesuaian Nomenklatur PTSP daerah</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1a1" :options="kelembagaan1"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai kelengkapan organisasi PTSP daerah</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1a2" :options="kelembagaan2"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>SDM</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai kecukupan jumlah SDM</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1b1" :options="sdm1"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai kompetensi SDM</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1b2" :options="sdm2"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Sarpras</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai kecukupan dan kelayakan sarana prasarana utama</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1c1" :options="sarpras"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai kecukupan dan kelayakan sarana prasarana pendukung</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1c2" :options="sarpras"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Kinerja Pelayanan</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai ketersediaan peraturan pendukung pelayanan perizinan di daerah</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1d1" :options="kinerja1"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai kepatuhan PTSP daerah pada peraturan perundangan yang ada</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1d2" :options="kinerja2"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Menilai kelengkapan SOP</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1d3" :options="kinerja1"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Menilai realisasi target investasi</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1d4" :options="kinerja4"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Kesinambungan</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai jumlah dan kualitas inovasi pelayanan perizinan berusaha</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1e1" :options="kesinambungan1"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai kegiatan koordinasi dengan provinsi</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1e2" :options="kesinambungan2"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Menilai ada tidaknya laporan evaluasi dan kinerja pelayanan perizinan berusaha</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p1e3" :options="kesinambungan3"></b-form-select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!--end: Datatable-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
              <!--end: Wizard Step 2-->
              
              <!--begin: Wizard Step 3-->
                            <div class="pb-5" data-wizard-type="step-content" style="margin-top:-40px;">
                                <h4 class="mb-10 font-weight-bold text-dark">2.	Kinerja PPB</h4>
                                
                                <div class="my-5">
                                    <div class="card card-custom">
                                        <div class="card-body">
                                            <!--begin: Datatable-->
                                            <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Indikator Penilaian</th>
                                                        <th>Jawaban</th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Organisasi</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai ketersediaan tim atau unit pelaksana PPB</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2a1" :options="kesinambungan3"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai program kerja PPB di daerah</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2a2" :options="kesinambungan3"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Implementasi</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai implementasi penerapan perizinan berusaha berbasis risiko</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2b1" :options="implementasi"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai implementasi penyederhanaan persyaratan dasar PB </td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2b2" :options="implementasi"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Menilai implementasi penyederhanaan perizinan berusaha sektor </td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2b3" :options="implementasi"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Menilai implementasi penyederhanaan persyaratan investasi pada sektor tertentu</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2b4" :options="implementasi"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" style="background-color:#EBEDF3"><b>Monitoring dan Evaluasi</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Menilai kegiatan monitoring dan evaluasi implementasi PPB</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2c1" :options="kesinambungan3"></b-form-select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Menilai keberadaan tindak lanjut dan laporan hasil monitoring dan evaluasi PPB</td>
                                                        <td>
                                                            <b-form-select disabled v-model="pm.p2c2" :options="kesinambungan3"></b-form-select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!--end: Datatable-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
              <!--end: Wizard Step 3-->
              
              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Sebelumnya
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                    data-toggle="modal" data-target="#verifikasiinput"
                  >
                    Selesai
                  </button>
                  <button id="next"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Langkah Selanjutnya
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>

    <!-- modal verifikasi selesai input -->
    <div class="modal fade" id="verifikasiinput" tabindex="-1" role="dialog" aria-labelledby="verifikasiinput" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="verifikasiinput">Konfirmasi</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <b>Demikian data dan informasi ini disampaikan dan saya bertanggung jawab atas kebenaran data dan informasi tersebut</b><br>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
            <button type="button" class="btn btn-primary" style="background-color: #030A8C; border-color: #030A8C" data-dismiss="modal" v-on:click="submit">Ya</button>
        </div>
        </div>
    </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  name: "PPK-Provinsi",
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        idpenilaian: localStorage.getItem('idpenilaian'),
        user: JSON.parse(localStorage.getItem('user')),
        idptspkabkota: JSON.parse(localStorage.getItem('idptspkabkota')),
        idptsp_kabkota: '',
        tahun: 2022,
        tanggal: localStorage.getItem('tanggal'),
        persetujuan: '',
        pm:[],
        status:[],
        kabkota:[],
        alasan:'',
        search: '',
        profil:[],

        kelembagaan1: [
          
          { value: 25, text: 'Sesuai' },
          { value: 0, text: 'Tidak sesuai' },
        ],
        kelembagaan2: [
          
          { value: 25, text: 'Lengkap' },
          { value: 10, text: 'Tidak lengkap' },
        ],
        sdm1: [
          
          { value: 25, text: 'Cukup' },
          { value: 10, text: 'Kurang' },
        ],
        sdm2: [
          
          { value: 25, text: 'Kompeten' },
          { value: 10, text: 'Kurang Kompeten' },
        ],
        sarpras: [
          
          { value: 25, text: 'Cukup dan layak' },
          { value: 10, text: 'Kurang' },
        ],
        kinerja1: [
          
          { value: 25, text: 'Lengkap' },
          { value: 10, text: 'Tidak Lengkap' },
        ],
        kinerja2: [
          
          { value: 25, text: 'Patuh' },
          { value: 0, text: 'Tidak patuh' },
        ],
        kinerja4: [
          
          { value: 25, text: 'Tercapai' },
          { value: 10, text: 'Tidak tercapai' },
        ],
        kesinambungan1: [
          
          { value: 25, text: 'Cukup dan baik' },
          { value: 10, text: 'Tidak cukup' },
          { value: 0, text: 'Tidak ada' },
        ],
        kesinambungan2: [
          { value: 25, text: 'Ada, lebih dari 1 kali' },
          { value: 10, text: 'Ada, 1 kali ' },
          { value: 0, text: 'Tidak ada' },
        ],
        kesinambungan3: [
          { value: 25, text: 'Ada' },
          { value: 0, text: 'Tidak ada' },
        ],
        implementasi: [
          { value: 25, text: 'Sepenuhnya' },
          { value: 10, text: 'Belum sepenuhnya' },
          { value: 0, text: 'Belum' },
        ],
        
      }
  },
  mounted() {
    this.loaddata();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penilaian Pemangku Kepentingan Provinsi", route: "provinsi" },
    //   { title: "PTSP" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    loaddata(){
      // untuk dapetin nama daerah
      axios.get(this.url+`/profils?filter=idptsp,=,`+this.$route.params.id, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                this.profil = response.data.data;
            }).catch(error=>{
                console.log(error)
                return error
                });
    
        // untuk load data ppknya
        axios.get(this.url+`/penilaian_prov_pemda?filter=idptsp,=,`+this.$route.params.id+`;tahun,=,2022`, {
                headers: {
                    'xth': this.token
                }
            })
        .then(response => {
            this.pm = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "Pengisian telah selesai",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });this.$router.push({ name: "dashboard" })
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  },
};
</script>
